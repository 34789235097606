import React, {useEffect, useState} from "react";
import QLoading from "../../../../common/components/QLoading";
import {UseSecondLayout} from "../../../../context/LayoutContext";
import {useTranslation} from "react-i18next";
import BusinessApi from "../../../../common/api/functions/Business";
import {ResponseInterface} from "../../../../interfaces/ResponseInterface";
import {config} from "../../../../config";
import SetupApi from "../../../../common/api/functions/Setup";
import {
    EconomicProductGroupInterface,
    LayoutInterface, ProdGrInterface, ProductGroupInterface,
    StripeProductInterface
} from "../../../../interfaces/economic/CommonInterface";
import Select from "react-select";
import {Col, Row} from "reactstrap";
import QButton from "../../../../common/components/QButton";
import setup from "../../../../common/api/functions/Setup";


const ProductGroup = () => {
    const { t } = useTranslation();
    const [stripeProducts, setStripeProducts] = useState<StripeProductInterface[]>([]);
    const [economicProductGroups, setEconomicProductGroups] = useState<EconomicProductGroupInterface[]>([]);
    const [productGroups, setProductGroups] = useState<{ [key: string]: string }>({});
    const [loading, setLoading] = useState(true);
    UseSecondLayout();
    useEffect(() => {
        if (loading) {
            loadProductGroup().then(async () => {

                await setLoading(false);
            });
        }
    }, [loading])

    const loadProductGroup = async () => {
        try {
            await SetupApi.productGroup().then(async (response: ResponseInterface | any) => {
                if (response?.status === config.STATUS_CODE.OK) {
                    setStripeProducts(response.data.data.stripe);
                    setEconomicProductGroups(response.data.data.economic);
                    let productG = response.data.data?.productGroup || []
                    let dataProdGroup:{ [key: string]: string } = {};
                    productG.forEach((productGroup:ProdGrInterface) => {
                        dataProdGroup[productGroup.number] = productGroup.group;
                    });
                    setProductGroups({...dataProdGroup});
                }
            })

        } catch (err) {
        }
        if (loading) {
            await setLoading(false);
        }
    }

    const ecoGroup = (value:string, onChange:Function | any) => {
        const options = economicProductGroups.map((e) => ({
            label: `${e.number} - ${e.name}`,
            value: e.number,
        }));
        return (
            <Select
                // className={'QSelectCustom'}
                isDisabled={false}
                isMulti={false}
                placeholder={''}
                onChange={(event: Event|any) => {
                    onChange(event)
                }}
                options={options}
                value={options.find(option => option.value?.toString() === value)}
            />
        )
    }

    const saveProductGroup = () => {
        setLoading(true);
        setup.updateProductGroup({...productGroups}).then(async (response) => {
            if (response.status === config.STATUS_CODE.OK) {

            }
            setLoading(false);
        });
    }

    return(
        <React.Fragment>
            <div className="col-md-10 relative">
                <QLoading loading={loading} />
                <div className="row ">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <div className="page-title-name">{t("product_setting.title")}</div>
                            </div>
                        </div>
                        <div className="col-lg-12 ">
                            <div className="business-detail bg-orange-bland-zp bdr-20 pd-25">
                                <div className="row">
                                    <div className={'d-flex justify-content-end gap-2'} style={{marginBottom:'10px'}}>
                                        <QButton
                                            type={'primary'}
                                            onClick={() => { saveProductGroup()}}
                                            content={t("common.button.save")}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <table className="table align-middle table-nowrap mb-0" id="customerTable">
                                        <thead className="table-light">
                                        <tr>
                                            <td colSpan={2} className={'prodgroup-stripe'} style={{width:'40%',textAlign:'center'}}><h5>Stripe Product</h5></td>
                                            <td colSpan={5} className={'prodgroup-eco'} style={{textAlign:'center'}}><h5>e-conomic Product Group</h5></td>
                                        </tr>
                                        <tr>
                                            <td  className={'prodgroup-stripe'}><strong>ID</strong></td>
                                            <td  className={'prodgroup-stripe'}><strong>Name</strong></td>
                                            <td className={'prodgroup-eco'}><strong></strong></td>
                                            <td className={'prodgroup-eco'}><strong>Domestic</strong></td>
                                            <td className={'prodgroup-eco'}><strong>EU</strong></td>
                                            <td className={'prodgroup-eco'}><strong>Abroad</strong></td>
                                            <td className={'prodgroup-eco'}><strong>Domestic without VAT</strong></td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {stripeProducts.map((stripeProd,index)=>{

                                            const selectedGroup = productGroups[stripeProd.number];
                                            const economicGroup = economicProductGroups.find(o => o.number.toString() === selectedGroup);
                                            const accDomestic = economicGroup?.saleAccounts.find(s => s.vatZoneNumber === 1)?.accountNumber || '';
                                            const accEU = economicGroup?.saleAccounts.find(s => s.vatZoneNumber === 2)?.accountNumber || '';
                                            const accAbroad = economicGroup?.saleAccounts.find(s => s.vatZoneNumber === 3)?.accountNumber || '';
                                            const accDomesticWithoutVat = economicGroup?.saleAccounts.find(s => s.vatZoneNumber === 4)?.accountNumber || '';

                                            return (
                                                <tr key={index}>
                                                    <td  className={'prodgroup-stripe'}>{stripeProd.number}</td>
                                                    <td  className={'prodgroup-stripe'}>{stripeProd.name}</td>
                                                    <td className={'prodgroup-eco'}>{ecoGroup(selectedGroup,(option:any)=>{
                                                        setProductGroups({...productGroups,[stripeProd.number]:option.value.toString()})

                                                    })}</td>
                                                    <td className={'prodgroup-eco'}>{accDomestic}</td>
                                                    <td className={'prodgroup-eco'}>{accEU}</td>
                                                    <td className={'prodgroup-eco'}>{accAbroad}</td>
                                                    <td className={'prodgroup-eco'}>{accDomesticWithoutVat}</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(ProductGroup);
