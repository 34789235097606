import React, {CSSProperties, useState} from "react";
import {FormGroup, Label, FormText, Input, FormFeedback, Spinner, Tooltip} from 'reactstrap'
import Select, {components} from 'react-select'
import {SelectOptionInterface} from "../../../interfaces/SelectOptionInterface";
import AsyncSelect from "react-select/async";

interface Props {
    value: string | any,
    promiseOptions: Function | any,
    onChange: Function | any,
    error?: string | any,
    placeholder?: string | any,
    isMulti?: boolean,
    label?: string | any,
    isDisabled?: boolean | any,
    defaultValue?:any
}

const QSelectSearch: React.FC<Props> = ({promiseOptions,
                                            onChange,
                                            label,
                                            value,
                                            placeholder = '',
                                            error = '',
                                            isMulti = false,
                                            isDisabled = false,
                                            defaultValue
                                        }) => {

    return (
        <div className="mb-3">
            {label ? (
                <Label>
                    {label}
                </Label>
            ) : null}
            <AsyncSelect
                cacheOptions
                className={'QSelectCustom'}
                isDisabled={isDisabled}
                isMulti={isMulti}
                placeholder={placeholder}
                loadOptions={promiseOptions}
                onChange={(event: Event|any) => {
                    if(onChange) onChange(event);
                }}
                defaultValue={defaultValue}
                // styles={{
                //     loadingMessage: (base) => ({
                //         ...base,
                //         backgroundColor: 'blue',
                //         color: 'white',
                //     }),
                // }}
            />

            {error ? (
                <FormFeedback>
                    {error}
                </FormFeedback>
            ) : null}

        </div>
    )
}

export default React.memo(QSelectSearch);
