import {RouteInterface} from "../interfaces/RouteInterface";
import {RouteDefine} from "./RouteDefine";
import Setup from "../features/public/Setup";
import {Config} from "../features/public/Config/Config";
// import Setup from "../features/public/Setup";

export const privateSetupRoute: Array<RouteInterface> = [
    // {path: RouteDefine.confirm, Component: Confirm},
    {path: RouteDefine.setup, Component: Setup},
    {path: RouteDefine.config, Component: Config},
    // {path: RouteDefine.document, Component: Document},
]
