import React, {useEffect, useState} from "react";
import {Col, Input, Label, Progress, Row} from "reactstrap";
import QButton from "../../../../common/components/QButton";
import {t} from "i18next";
import {setLoading} from "../../../../app/rootReducer";

interface Props {
    open: number | boolean, // open > 0 show
    setOpen: Function,
    callIntegration: Function,
    progress: number,
    showForceImport?:boolean
}

const BodyModalIntegrationComponent: React.FC<Props> = ({showForceImport = false,progress, setOpen, open, callIntegration, ...props}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [forceImport, setForceImport] = useState<boolean>(false);
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const handleSubmit = () => {
        if (progress === 0) {
            callIntegration({startDate,endDate,forceImport}).then(() => {
                console.log('QButton');
            })
        } else if (progress === 100) {
            setOpen(false);
        }

    }
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Progress style={{
                        height: '15px'
                    }}
                              striped animated={true} color="danger"
                              value={progress}>{progress > 0 && progress < 100 ? 'Loading...' : (progress === 100 ? 'Done' : '')}</Progress>
                    <br></br>
                    <br/>

                    <div className={`slide-down-container ${isExpanded ? 'expanded' : ''}`}>
                        <div className="mb-3">
                            <Label>Start Date</Label>
                            <Input
                                type="datetime-local"
                                className={'form-control-zp'}
                                // style={{ paddingLeft: '20px' }}
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <Label>End Date</Label>
                            <Input
                                type="datetime-local"
                                className={'form-control-zp'}
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>

                        {showForceImport && ( <div className="mb-3">
                            <Label>Force Import</Label>
                            <Input
                                style={{marginLeft:'10px'}}
                                type="checkbox"
                                className={'form-control-zp'}
                                checked={forceImport}
                                onChange={(e) => setForceImport(e.target.checked)}
                            />
                        </div>)}
                    </div>


                    <div className={'text-end'}>
                        <QButton
                            type="secondary"
                            onClick={toggleExpand}
                            content={isExpanded ? 'Hide Advance' : 'Show Advance'}
                        />
                        <QButton
                            type={(progress === 0 ? 'primary' : 'success')}
                            onClick={handleSubmit}
                            content={(progress > 0 && progress < 100 ? 'Loading...' : (progress === 100 ? 'Close' : t("common.button.submit")))}
                        />
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default BodyModalIntegrationComponent
