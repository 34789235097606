import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import common_en from "./en/common.json";
import order_en from "./en/order.json";
import order_vi from "./vi/order.json";
import common_vi from "./vi/common.json";
import log_en from "./en/log.json";
import log_vi from "./vi/log.json";
import auth_vi from "./vi/auth.json";
import auth_en from "./en/auth.json";
import product_en from "./en/product.json";
import product_vi from "./vi/product.json";
import customer_en from "./vi/customer.json";
import customer_vi from "./vi/customer.json";
import business_en from "./en/business.json";
import business_vi from "./vi/business.json";
import economic_en from "./en/economic.json";
import economic_vi from "./vi/economic.json";
import mobilepay_en from "./en/mobilepay.json";
import product_setting_en from "./en/product_setting.json";
import product_setting_vi from "./vi/product_setting.json";

i18next
    .use(initReactI18next)
    .init({
        debug: true,
        resources: {
            en: {
                translation: {
                    common: common_en,
                    order: order_en,
                    auth: auth_en,
                    product: product_en,
                    customer: customer_en,
                    business: business_en,
                    economic: economic_en,
                    mobilepay: mobilepay_en,
                    log: log_en,
                    product_setting: product_setting_en,

                }
            },
            vn: {
                translation: {
                    common: common_vi,
                    auth: auth_vi,
                    order: order_vi,
                    product: product_vi,
                    customer: customer_vi,
                    business: business_vi,
                    economic: economic_vi,
                    log: log_vi,
                    product_setting: product_setting_vi,
                }
            },
        },
        lng: 'en',
        fallbackLng: 'en',
        whitelist: ['vn', 'en'],

        interpolation: {
            escapeValue: false,
        },
    });
