import {RouteInterface} from "../interfaces/RouteInterface";
import {RouteDefine} from "./RouteDefine";
import {Dashboard} from "../features/private/Dashboard";
import {Integrate} from "../features/private/Integrate";
import {Business} from "../features/private/Business";
import {Economic} from "../features/private/Economic";
import Invoice from "../features/private/Log/Invoice";
import Stripe from "../features/private/Stripe";
import Payment from "../features/private/Log/payment";
import Payout from "../features/private/Log/payout";
import Subscription from "../features/private/Log/subscription";
import ProductGroup from "../features/private/Settings/ProductGroup";
// import Setup from "../features/public/Setup";

export const privateRoute: Array<RouteInterface> = [
    {path: RouteDefine.dashboard, Component: Dashboard},

    {path: RouteDefine.integrate, Component: Integrate},
    {path: RouteDefine.business, Component: Business},
    {path: RouteDefine.economic, Component: Economic},
    {path: RouteDefine.stripe, Component: Stripe},
    {path: RouteDefine.invoice, Component: Invoice},
    {path: RouteDefine.payment, Component: Payment},
    {path: RouteDefine.payout, Component: Payout},
    {path: RouteDefine.subscription, Component: Subscription},
    {path: RouteDefine.settingsProductGroup, Component: ProductGroup},
]
